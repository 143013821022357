const logApiError = async (message, response) => {
    const err = await response
        .text()
        .then((text) => {
            try {
                return JSON.parse(text);
            } catch {
                return text;
            }
        })
        .catch(() => 'an unknown error');

    console.error(message, err);
};

const PLAYLIST_API_BASE_URL = 'https://playlist.stream.schibsted.media/vgtv';

const getPlaylist = async (playlistId) => {
    const response = await fetch(
        `${PLAYLIST_API_BASE_URL}/${playlistId}?additional=metadata`,
    );

    if (!response.ok) {
        await logApiError('failed to fetch assets', response);

        return [];
    }

    const { resolved } = await response.json();

    return resolved;
};

const SVP_API_BASE_URL = 'https://svp.vg.no/svp/api/v1/vgtv';

const searchAssets = async (ids) => {
    if (!ids.length) {
        return [];
    }

    const params = new URLSearchParams({
        appName: 'vg-front',
        filter: `id::${ids.join(',')}`,
        limit: String(ids.length),
        additional: 'metadata',
    });

    const response = await fetch(
        `${SVP_API_BASE_URL}/search?${params.toString()}`,
    );

    if (!response.ok) {
        await logApiError('failed to fetch assets', response);

        return [];
    }

    const {
        _embedded: { assets },
    } = await response.json();

    return assets;
};

const SEGMENTATION_API_BASE_URL = 'https://papi.vg.no/user-segmentation-api';

const getContent = async (sig) => {
    const response = await fetch(
        `${SEGMENTATION_API_BASE_URL}/v1/recommended-simple/vgtv-stories-front`,
        {
            headers: {
                Authorization: `Bearer ${sig}`,
            },
        },
    );

    if (!response.ok) {
        await logApiError('failed to fetch recommended content', response);

        return { assets: [], params: {} };
    }

    const data = await response.json();

    const ids = data.slice(0, 8).map((id) => Number(id.split(':')[2]));

    const assets = await searchAssets(ids);

    return {
        assets: ids
            .map((id) => assets.find((asset) => asset.id === id))
            .filter(Boolean),
        params: {
            strategyName: response.headers.get('x-strategy-name'),
            strategyVersion: response.headers.get('x-strategy-version'),
            trackingTag: response.headers.get('x-tracking-tag'),
        },
    };
};

export const getData = async ({ session, playlistId, row }) => {
    if (!session || playlistId !== 'fWydCB4Eoi1zcJ4nVqwJJ50Y') {
        return {
            utm: {
                utm_source: 'vgfront',
                utm_content: 'short-videos-widget',
            },
        };
    }

    const { sig } = session;

    const { assets, params } = await getContent(sig);

    if (assets.length > 3) {
        return {
            items: assets.map((asset, index) => {
                return {
                    asset,
                    playlistId,
                    utm: {
                        utm_source: 'vgfront-personalized',
                        utm_content: `hovedlopet_row${row}_pos${index + 1}`,
                        utm_medium: 'short-videos-widget',
                        utm_term: `vgtv_stories_front-test-${params.strategyName}-${params.strategyVersion}-${params.trackingTag}`,
                    },
                };
            }),
        };
    }

    const fallback = await getPlaylist(playlistId);

    return {
        items: fallback.map((asset, index) => {
            return {
                asset,
                playlistId,
                utm: {
                    utm_source: 'vgfront',
                    utm_content: `hovedlopet_row${row}_pos${index + 1}`,
                    utm_medium: 'short-videos-widget',
                    utm_term: 'vgtv_stories_front-control-default-1-',
                },
            };
        }),
    };
};
