import { render } from 'preact';
import VgtvStories from '@vgno/vgtv-stories';
import '@vgno/vgtv-stories/dist/index.css';
import { resolveExperiment } from '../../utils/experiments';
import { getData } from './personalized-stories';
import { isVGApp } from '../../utils/device';

/**
 * @param {Object} data
 * @param {Object} [custom]
 */
const trackRawEngagement = (data, custom) => {
    window.pulse((sdk) =>
        sdk.track('Engagement', {
            ...data,
            object: {
                ...data.object,
                custom: {
                    ...data.object.custom,
                    ...custom,
                },
            },
        }),
    );
};

// eslint-disable-next-line no-unused-vars
const EXPERIMENTS = {};

/**
 * @param {string[]} playlistIds
 */
const resolvePlaylistId = (playlistIds) => {
    const { variant } = resolveExperiment({
        experiment: {
            name: `stories-${playlistIds.join('-')}`,
            variants: playlistIds.map((playlistId, index) => {
                let weight = Math.floor(100 / playlistIds.length);

                if (index === playlistIds.length - 1) {
                    // all variants need to sum up to 100
                    weight += 100 - weight * playlistIds.length;
                }

                return {
                    name: playlistId,
                    weight,
                };
            }),
        },
    });

    return variant;
};

/**
 * @param {string[]} experiments
 * @param {string} playlistId
 */
// eslint-disable-next-line no-unused-vars
const resolveExperiments = (experiments, playlistId) => {
    const resolved = {};

    if (playlistId === 'P6h6mvP4hgYrXqc5iSPDvfAU') {
        resolved.vgLista = 'enabled';
    }

    return resolved;
};

const getUserSession = async () => {
    try {
        const { sig, userId } = await window.SPiD_Identity.hasSession();

        return sig && userId ? { sig, userId } : undefined;
    } catch {
        return undefined;
    }
};

const VGTVStories = ({ node, trackEngagement, trackTeaser, row, props }) => {
    const { playlistId: playlistIdProp, experiments: experimentsProp } = props;

    const playlistId =
        typeof playlistIdProp === 'string'
            ? playlistIdProp
            : resolvePlaylistId(playlistIdProp);
    const experiments = resolveExperiments(experimentsProp, playlistId);

    const id = 'sdrn:vg:content:widget:vgtv-stories';

    const observer = new IntersectionObserver(
        (entries) => {
            if (entries[0].isIntersecting) {
                trackEngagement({
                    elementAction: 'View',
                    elementName: 'VGTV Stories',
                    overrideId: id,
                    custom: {
                        ...experiments,
                        playlistId,
                    },
                });
                observer.disconnect();
            }
        },
        {
            threshold: [0.5],
        },
    );
    observer.observe(node);

    getUserSession()
        .then((session) => getData({ session, playlistId, row }))
        .then(({ utm, items }) => {
            render(
                <div
                    style={{
                        '--vertical-video-width': '50vw',
                        '--vertical-video-height': '90vw',
                    }}
                >
                    <VgtvStories
                        title={props.title}
                        variant="vertical"
                        playlistId={playlistId}
                        trackEngagement={(data) =>
                            trackRawEngagement(data, {
                                ...experiments,
                                playlistId,
                            })
                        }
                        trackTeaser={trackTeaser}
                        row={row}
                        utm={utm}
                        experiments={experiments}
                        items={items}
                        enableOverlay={!isVGApp()}
                    />
                </div>,
                node,
            );
        });
};
export default VGTVStories;
